/* eslint-disable no-useless-escape */
document.addEventListener('DOMContentLoaded', function () {
    const form = document.querySelector('.feedback-form');
    if (!form) return;

    form.addEventListener('submit', function (event) {
        event.preventDefault();

        const name = form.querySelector('input[name="name"]').value;
        const email = form.querySelector('input[name="email"]').value;
        const phone = form.querySelector('input[name="phone"]').value;
        const privacyPolicy = form.querySelector(
            'input[name="privacy-policy"]',
        ).checked;

        const nameRegex = /^[a-zA-Z]{2,}$/;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const phoneRegex =
            /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/;

        if (!nameRegex.test(name)) {
            alert(
                'Введите ваше имя, состоящее не менее из 2 символов и содержащее только буквы!',
            );
            return;
        }
        if (!emailRegex.test(email)) {
            alert('Введите валидный email!');
            return;
        }
        if (!phoneRegex.test(phone)) {
            alert(
                'Введите ваш номер телефона, начинающийся с 7 или 8 и содержащий 11 цифр!',
            );
            return;
        }

        if (!privacyPolicy) {
            alert(
                'Вы не согласились с политикой обработки персональных данных!',
            );
            return;
        }

        form.submit();
    });
});
